var copy = {};

//
// Basic Page
//
copy.baseTitle = 'Dave Fearon';
copy.titleDivider = ' | ';
copy.subTitle = 'Engineer | Woodworker | Amateur Photographer';


//
// Analytics
//
copy.gaAccount = 'UA-2895777-3';

//
// Copy
//
copy.about = {
	'long': "Born and raised in New Brunswick, Canada, I lived in St. John's, Newfoundland for a while, settled in Ottawa, Ontario, made my way down to San Francisco for a spell, but now living in Oakville, Ontario. Now working for <a href='https://www.slack.com' target='_blank'>Slack</a> as a Senior Engineer. I honed my skills studying Interactive Multimedia and Design (a fancy title for half-design, half-software development) at Carleton University. I love to find a new adventure and am always up for a game of soccer or hockey.",
	'short': "TL;DR I work at <a href='https://www.slack.com' target='_blank'>Slack</a> as a Senior Engineer and live in Oakville, ON.",
};

var jobs = [
	{
		'title': 'Senior Engineer',
		'company': 'Slack Inc.',
		'location': 'Toronto, ON',
		'when': {
			'start': 'February 2018',
			'end': 'PRESENT'
		},
		'description': 'Transfered to the Toronto office to help build out the Customer Experience (CE) engineering team and work closely with the CE agents to build and improve features that directly address customer issues. Working in a full stack engineering capacity.'
	},
	{
		'title': 'Senior Frontend Engineer',
		'company': 'Slack Inc.',
		'location': 'San Francisco, CA',
		'when': {
			'start': 'February 2016',
			'end': 'February 2018'
		},
		'description': 'Helped to bring the Grid/Enterprise project from an idea to a fully-featured release in the client app. Worked in a Frontend Engineer role.'
	},
	{
		'title': 'Frontend Engineer - Flickr',
		'company': 'Yahoo, Inc.',
		'location': 'San Francisco, CA',
		'when': {
			'start': 'July 2014',
			'end': 'January 2016'
		},
		'description': 'Helping build Flickr on the Frontend Team. Notable projects include wall art, stats pages for Pro users, Pro subscription purchasing, and the Javascript SDK.'
	},
	{
		'title': 'Data / Frontend Engineer',
		'company': 'Jetpac',
		'location': 'San Francisco, CA',
		'when': {
			'start': 'November 2012',
			'end': 'June 2014'
		},
		'description': 'Engineer for the proprietary applications built by the startup. Main focus is on frontend development but have contributed to all levels of the stack including, but not limited to, data parsing, database management, server-side API endpoints, and iOS application development.'
	},
	{
		'title': 'Software Developer Contractor',
		'company': 'Sector81',
		'location': 'Ottawa, ON',
		'when': {
			'start': 'January 2012',
			'end': 'March 2013'
		},
		'description': 'Software developer contractor for numerous projects.'
	},
	{
		'title': 'Lead Software Developer / Technical Lead',
		'company': 'In-Touch Insight Systems Inc.',
		'location': 'Ottawa, ON',
		'when': {
			'start': 'March 2011',
			'end': 'November 2012'
		},
		'description': 'Lead Software Developer for the consumer marketing division. Projects include html/js based offline web applications, java based web applications, ruby based project pipeline tools and api development.'
	},
	{
		'title': 'Serverside Developer',
		'company': 'Fuel Industries',
		'location': 'Ottawa, ON',
		'when': {
			'start': 'May 2009',
			'end': 'March 2011'
		},
		'description': 'Lead Serverside Developer on numerous small and large scale projects for various big name clients and agencies. I strived for a pixel perfect and Standards Compliant front end with a strong focus on delivering a fast and scalable backend to support whatever might happen in the future.'
	},
	{
		'title': 'Graphic and Website Designer',
		'company': 'Carleton University',
		'location': 'Ottawa, ON',
		'when': {
			'start': 'May 2008',
			'end': 'May 2009'
		},
		'description': 'Designed and built several online systems. Managed and maintained multiple sub-sites within the Carleton domain. Designed numerous promotional items such as mini-flyers and posters. Website usability reviewer for various offices during a Carleton-wide redesign and CMS integration.'
	},
	{
		'title': 'Web Developer and Designer',
		'company': 'Talent First Network',
		'location': 'Ottawa, ON',
		'when': {
			'start': 'October 2007',
			'end': 'May 2008'
		},
		'description': 'Designed a rebrand of the initiative for an online portal system. Developed complete web packages for various companies being supported by Talent First Network.'
	},
	{
		'title': 'Co-Founder / Designer and Developer',
		'company': 'The BIT Invasion',
		'location': 'Ottawa, ON',
		'when': {
			'start': 'January 2006',
			'end': 'December 2008'
		},
		'description': 'Co-founded a business with a friend/fellow student at Carleton University designing various forms of interactive media. Projects include websites, corporate identity packages and online business set-up/design.'
	}
];
copy.jobs = jobs;

var education = [
	{
		'what': 'BIT: Interactive Multimedia and Design',
		'where': 'Carleton University',
		'location': 'Ottawa, ON',
		'when': {
			'start': 'September 2005',
			'end': 'April 2009'
		},
		'description': 'Graduated from the Bachelor of Information Technology: Interactive Multimedia and Design with a B+ GPA.'
	},
	{
		'what': 'Part-Time Studies',
		'where': 'Carleton University / Ottawa University',
		'location': 'Ottawa, ON',
		'when': {
			'start': 'September 2004',
			'end': 'June 2005'
		},
		'description': 'Enrolled as a part-time student at both Carleton University and Ottawa University in an effort to ensure my placement in the BIT:IMD program as well as gain an advanced standing upon entry.'
	},
	{
		'what': 'Civil Engineering Technology',
		'where': 'St. Lawrence College',
		'location': 'Kingston, ON',
		'when': {
			'start': 'September 2002',
			'end': 'April 2004'
		},
		'description': 'Enrolled in the Civil Engineering Technology program at the Kingston campus.'
	}
];
copy.education = education;

module.exports = copy;
