import React from 'react';

const Footer = () => {
	return (
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-lg-3 d-flex justify-content-lg-end">
						<span className="section-title">Contact</span>
					</div>

					<div className="col-lg-6">
						<p><span>Email</span><br/>
							<a href="mailto:dave.fearon@gmail.com">dave.fearon@gmail.com</a><br/>
						</p>
					</div>

					<div className="col-lg-3">
						<p><span>Social Links</span><br />
							<a href="https://www.flickr.com/davefearon"><i className="fa fa-flickr"></i></a>
							<a href="https://twitter.com/davefearon"><i className="fa fa-twitter"></i></a>
							<a href="https://www.facebook.com/davefearon"><i className="fa fa-facebook"></i></a>
							<a href="http://instagram.com/davefearon"><i className="fa fa-instagram"></i></a>
							<a href="https://foursquare.com/davefearon"><i className="fa fa-foursquare"></i></a>
							<a href="https://github.com/davefearon"><i className="fa fa-github-alt"></i></a>
							<a href="http://www.linkedin.com/pub/dave-fearon/7/8a4/437"><i className="fa fa-linkedin"></i></a>
						</p>
					</div>
				</div>
			</div>


			<div id="copyright">
				<div className="container">
					<p>Made with <i className="heart fa fa-heart"></i> by <span>Dave Fearon</span> .</p>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
