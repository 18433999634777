import React from 'react';
const classNames = require('classnames');

const { education, jobs } = require('./../data/copy');

const Body = () => {
	return (
		<main>
			<div className="container">
				<div className="row">
					<div className="col-lg-3 d-flex justify-content-lg-end">
						<span className="section-title">Work</span>
					</div>

					{jobs.map((job, index) => {
						const clazz = classNames('job col-lg-9', {
							'offset-lg-3': index !== 0,
						});
						return (
							<div className={clazz} key={job.when.start}>
								<div className="row">
									<div className="col-lg-8">
										<div className="job--title">
											{job.title}
										</div>
										<div className="job--company">
											{job.company}
										</div>
										<div className="job--location">
											{job.location}
										</div>

										<div className="d-lg-none">
											<p className="dates"><span className="smol">{job.when.start} - {job.when.end}</span></p>
										</div>

										<p className="job--description">{job.description}</p>
									</div>

									<div className="col-lg-4 dates-column d-lg-block">
										<p className="dates"><span className="smol">{job.when.start} - {job.when.end}</span></p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-12">
						<hr />
					</div>
				</div>
			</div>

			<div className="container mt-3 mt-lg-5">
				<div className="row">
					<div className="col-lg-3 d-flex justify-content-lg-end">
						<span className="section-title">Education</span>
					</div>

					{education.map((skool, index) => {
						const clazz = classNames('job col-lg-9', {
							'offset-lg-3': index !== 0,
						});
						return (
							<div className={clazz} key={skool.when.start}>
								<div className="row">
									<div className="col-lg-8">
										<div className="job--title">
											{skool.what}
										</div>
										<div className="job--company">
											{skool.where}
										</div>
										<div className="d-lg-none">
											<p className="dates"><span className="smol">{skool.when.start} - {skool.when.end}</span></p>
										</div>
										<p className="job--description">{skool.description}</p>
									</div>

									<div className="col-lg-4 dates-column d-lg-block">
										<p className="dates"><span className="smol">{skool.when.start} - {skool.when.end}</span></p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>

		</main>
	);
}

export default Body;
