import React, { useState } from 'react';
import { useScrollPosition } from './scroll';
const classNames = require('classnames');

const { baseTitle, subTitle } = require('./../data/copy');

const Bar = ({ isVisible, onClick }) => {
	return (
		<div className={classNames('bar', { 'bar--visible': isVisible })} onClick={onClick}>
			<h1>{baseTitle}</h1>
			<h5>{subTitle}</h5>
		</div>
	);
};

const Header = ({ onClick = () => {}, inverted = false }) => {
	const [isSticky, setIsSticky] = useState(false);

	useScrollPosition(position => {
		const windowHeight = window.innerHeight;
		setIsSticky(position > windowHeight * 0.55);
	});

	return (
		<>
			<Bar isVisible={isSticky} inverted={inverted} onClick={onClick} />
			<header onClick={onClick}>
				<div className="titles">
					<h1>{baseTitle}</h1>
					<h5>{subTitle}</h5>
				</div>

				<div className="down-arrow"></div>
			</header>
		</>
	);
}

export default Header;
