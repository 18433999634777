import React, { useState } from 'react';
const parse = require('html-react-parser');

const { about } = require('./../data/copy');

const About = () => {
	const [showShort, setShowShort] = useState(false);

	function handleClick(e) {
		if (e.target.nodeName !== 'A') {
			setShowShort(!showShort);
		}
	}

	function renderCopy() {
		if (showShort) {
			return (<p>{parse(about.short)}</p>);
		}

		return (<p>{parse(about.long)}</p>);
	}

	return (
		<div className="about" onClick={handleClick}>
			<div className="container">
				{renderCopy()}
			</div>
		</div>
	);
}

export default About;
