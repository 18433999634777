import React, { useState } from 'react';
import Header from './header';
import About from './about';
import Body from './body';
import Footer from './footer';

const classNames = require('classnames');

const App = () => {
  const [inverted, setInverted] = useState(false);

  function handleClick() {
    setInverted(!inverted);
  }

  return (
    <div className={classNames('', { 'inverted': inverted })}>
      <Header onClick={handleClick} />
      <About />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
